<template>
  <div class="mb-2">
    Normal style
    <div class="py-3"></div>
    <base-button class=" px-6 py-2">Normal</base-button>
    <div class="py-3"></div>
    <base-button class="bg-primary text-white  px-6 py-2">Primary</base-button>
    <div class="py-3"></div>
    <base-button class="bg-secondary text-white  px-6 py-2"
      >Secondary</base-button
    >
  </div>
  <div class="mb-2">
    Rounded style
    <div class="py-3"></div>
    <base-button class="border-16 px-6 py-2">Normal</base-button>
    <div class="py-3"></div>
    <base-button class="border-16 bg-primary text-white px-6 py-2"
      >Primary</base-button
    >
    <div class="py-3"></div>
    <base-button class="border-16 bg-secondary text-white px-6 py-2"
      >Secondary</base-button
    >
  </div>
  <div class="mb-2">
    Rounded block style
    <div class="py-3"></div>
    <base-button class="w-full border-16">Normal</base-button>
    <div class="py-3"></div>
    <base-button class="w-full border-16 bg-primary text-white"
      >Primary</base-button
    >
    <div class="py-3"></div>
    <base-button class="w-full border-16 bg-secondary text-white"
      >Secondary</base-button
    >
  </div>
  <div class="mb-2">
    Rounded block large style
    <div class="py-3"></div>
    <base-button class="h-12 w-full border-16">Normal</base-button>
    <div class="py-3"></div>
    <base-button class="h-12 w-full border-16 bg-primary text-white"
      >Primary</base-button
    >
    <div class="py-3"></div>
    <base-button class="h-12 w-full border-16 bg-secondary text-white"
      >Secondary</base-button
    >
  </div>
  <div class="mb-2">
    Rounded block large outlined style
    <div class="py-3"></div>
    <base-button class="h-12 w-full border-16 bg-none border-1  shadow-none"
      >Normal</base-button
    >
    <div class="py-3"></div>
    <base-button
      class="h-12 w-full border-16 bg-transparent bg-none border-1 border-primary shadow-none"
      >Primary</base-button
    >
    <div class="py-3"></div>
    <base-button
      class="h-12 w-full border-16 bg-transparent bg-none border-1 border-secondary text-secondary shadow-none"
      >Secondary</base-button
    >
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton';

export default {
  name: 'UIButton',
  components: { BaseButton },
};
</script>

<style scoped></style>
